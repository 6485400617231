import { Button, List, Typography } from 'antd';
import { Link } from 'gatsby';
import { isEmpty } from 'lodash';
import React from 'react';

import Section from '../../components/Section';
import Breadcrumb from '../../components/collection/CollectionBreadcrumb';
import CollectionCard from '../../components/collection/CollectionCard';
import MainLayout from '../../components/layout/MainLayout';
import SearchEngineOptimization from '../../components/utility/seo';
import { useCollection } from '../../hooks/use-collection';
import { Collection } from '../../types/Collection';

const { Text } = Typography;

const MAX_ITEMS = 6;

const CollectionList = ({
  parent,
  items,
}: {
  parent: Collection;
  items: Collection[];
}) => {
  const showMore = items.length > MAX_ITEMS;

  return (
    <div className="mb-3 overflow-hidden rounded-[6px] border border-light-divider shadow-small">
      <div className="border-b border-light-divider bg-white px-6 py-3">
        <Text>{parent.title}</Text>
      </div>
      <div className="bg-light-lighter p-6">
        <div className="grid w-full grid-cols-2 gap-3 md:grid-cols-4 lg:grid-cols-6">
          {items.slice(0, MAX_ITEMS).map((collection) => (
            <CollectionCard collection={collection} />
          ))}
        </div>
        {showMore && (
          <Link to={`/collections/${parent.handle}`}>
            <Button className="mt-6 !text-primary-6">ดูประเภทสินค้านี้</Button>
          </Link>
        )}
      </div>
    </div>
  );
};

const AllCollectionPage = () => {
  const { hierarchyCollections } = useCollection();
  if (isEmpty(hierarchyCollections)) return null;
  return (
    <MainLayout>
      <SearchEngineOptimization title="ประเภทสินค้าทั้งหมด" />
      <Breadcrumb />
      <Section className="py-6">
        <List
          dataSource={hierarchyCollections}
          renderItem={(item) => (
            <CollectionList parent={item} items={item.children} />
          )}
        />
      </Section>
    </MainLayout>
  );
};

export default AllCollectionPage;
